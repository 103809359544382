<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.exercises.title')" :description="$t('headers.exercises.description')"/>

    <!-- Account level -->
    <AccountLevel :level="$store.state.profile.level" :title="$store.state.profile.title"/>

    <!-- Square buttons -->
    <SquareButtonGroup :buttons="buttons"/>

    <!-- Footer -->
    <Footer :quote="true"/>

  </v-container>
</template>

<script>
import Header from '../components/HeaderInfo';
import AccountLevel from '../components/AccountLevel';
import SquareButtonGroup from '../components/SquareButtonGroup';
import Footer from '../components/Footer';

export default {
  name: 'MainExercises',
  components: {Header, AccountLevel, SquareButtonGroup, Footer},
  data: () => ({
    buttons: [
      {icon: 'mdi-thermometer-chevron-up', title: 'nav.warm-up', link: '/warm-up'},
      {icon: 'mdi-dumbbell', title: 'nav.training', link: '/training'},
      {icon: 'mdi-account-group', title: 'nav.group', link: '/group'}
    ]
  })
};
</script>
